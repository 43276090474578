import { reactive, ref } from "vue";
import { list } from "@/api/member/whitePlateNumberApi";
import useMessage from "@/hooks/useMessage";
const useWhitePlateNumber = () => {
  const { showErr } = useMessage();
  const loading = ref(false);
  const dataList = reactive({ list: [] });
  const total = ref(0);
  //{0:未知,1:蓝,2:黄,3:黑,4:白,5:绿}'
  const plateColors = ref([
    { value: 0, label: "未知" },
    { value: 1, label: "蓝牌" },
    { value: 2, label: "黄牌" },
    { value: 3, label: "黑牌" },
    { value: 4, label: "白牌" },
    { value: 5, label: "绿牌" },
  ]);

  const whitePlateNumberVo = reactive({
    id: "",
    phone: "",
    plateNumber: "",
    createTime: "",
    createdBy: "",
  });

  const columns = [
    {
      title: "序号",
      align: "center",
      width: "80px",
      customRender: ({ index }) => {
        return index + 1;
      },
    },
    {
      title: "车牌号",
      align: "center",
      dataIndex: "plateNumber",
      slots: { customRender: "plateNumber" },
    },
    {
      title: "手机号码",
      align: "center",
      dataIndex: "phone",
    },
    {
      title: "添加时间",
      align: "center",
      dataIndex: "createTime",
    },
    {
      title: "添加人",
      align: "center",
      dataIndex: "createdBy",
    },
    {
      title: "操作",
      align: "center",
      width: "240px",
      slots: { customRender: "operation" },
    },
  ];
  const loadData = async (page, pageSize) => {
    loading.value = true;
    try {
      let res = await list(
        params.plateNumber,
        params.phone,
        params.plateColor,
        page,
        pageSize,
      );
      dataList.list = res.data;
      total.value = res.total;
      loading.value = false;
    } catch (e) {
      loading.value = false;
      await showErr(e);
    }
  };

  const params = reactive({
    plateNumber: "",
    phone: "",
    plateColor: null,
  });
  return {
    loading,
    params,
    dataList,
    total,
    columns,
    loadData,
    whitePlateNumberVo,
    plateColors,
  };
};
export default useWhitePlateNumber;
